import _objectSpread from "/home/carlos.matus/wisetack/clients/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _toConsumableArray from "/home/carlos.matus/wisetack/clients/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import moment from "moment";
import { tldExists } from "tldjs";
import { logAmplitudeEvent } from "../components/Amplitude";
import { Messages } from "./Messages";
var OK = "";
export var Regex = {
  PHONE_REGEX: /^\d+$/,
  EMAIL_REGEX: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/i,
  STATE_REGEX: /^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/,
  ZIP_CODE_REGEX: /(^\d{5}$)/,
  ROUTING_NUMBER_REGEX: /(^\d{9}$)/,
  EIN_REGEX: /(^\d{9}$)/,
  SSN_REGEX: /(^\d{9}$)/,
  SSN4_REGEX: /(^\d{4}$)/,
  ACCOUNT_NUMBER_REGEX: /(^\d{4,17}$)/,
  WEBSITE_REGEX: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/)?$/,
  CLEAN_ASCII_REGEX: /^[ -~]*$/,
  SAFE_TEXT_REGEX: /^[a-zA-Z0-9\s/'"&#=:;,.+_!%)(|-]+$/,
  SAFE_TEXT_REGEX_REPLACE: /[a-zA-Z0-9\s/'"&#=:;,.+_!%)(|-]/g
};
export var logValidationError = function logValidationError(validateFn, application, page, field) {
  var props = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var suggestFn = arguments.length > 5 ? arguments[5] : undefined;
  return function () {
    var values = null;
    var suggestCallback = null;

    for (var _len = arguments.length, val = new Array(_len), _key = 0; _key < _len; _key++) {
      val[_key] = arguments[_key];
    }

    if (val.length > 1 && typeof val[val.length - 1] === "function") {
      values = val.slice(0, val.length - 1);
      suggestCallback = val[val.length - 1];
    } else {
      values = val;
    }

    var message = validateFn.apply(void 0, _toConsumableArray(values));

    if (message && message.length > 0) {
      props = _objectSpread(_objectSpread({}, props), {}, {
        application: application,
        page: page,
        field: field,
        message: message
      });
      logAmplitudeEvent("Validation Error", props);
    }

    if (suggestCallback && suggestFn) {
      suggestFn(values, suggestCallback);
    }

    return message;
  };
};

var removeAllDuplicates = function removeAllDuplicates() {
  var _String$prototype;

  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return (_String$prototype = String.prototype).concat.apply(_String$prototype, _toConsumableArray(new Set(str)));
};

export var validateFirstName = function validateFirstName(value) {
  if (!value) return Messages.ENTER_FIRST_NAME;
  if (value.length < 2) return Messages.TWO_LETTERS_MINIMUM;
  return OK;
};
export var validateLastName = function validateLastName(value) {
  if (!value) return Messages.ENTER_LAST_NAME;
  if (value.length < 2) return Messages.TWO_LETTERS_MINIMUM;
  return OK;
};
export var validateSafeTextFirstName = function validateSafeTextFirstName(value) {
  if (!value) return Messages.ENTER_FIRST_NAME;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return OK;
};
export var validateSafeTextLastName = function validateSafeTextLastName(value) {
  if (!value) return Messages.ENTER_LAST_NAME;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return OK;
};
export var validateEmail = function validateEmail(value) {
  if (!value) return Messages.ENTER_EMAIL;
  if (!Regex.EMAIL_REGEX.test(value)) return Messages.ENTER_VALID_EMAIL;
  if (!tldExists(value)) return Messages.ENTER_VALID_TLD;
  return OK;
};
export var validateWebsite = function validateWebsite(value) {
  if (!value) {
    return OK;
  }

  if (!value.match(new RegExp(Regex.WEBSITE_REGEX))) {
    return Messages.ENTER_VALID_WEBSITE;
  }

  return OK;
};
export var validateMobileNumber = function validateMobileNumber(value) {
  if (!value) return Messages.ENTER_PHONE;
  var mobileNumber = value.replace(/-/g, "");
  if (!Regex.PHONE_REGEX.test(mobileNumber)) return Messages.ENTER_VALID_PHONE;
  if (mobileNumber.length === 10) return OK;
  if (mobileNumber.length === 11 && mobileNumber[0] === "1") return OK;
  return Messages.ENTER_VALID_PHONE_ALT;
};
export var validateMobileNumberInDomesticFormat = function validateMobileNumberInDomesticFormat(value) {
  if (!value) return Messages.ENTER_PHONE;
  var mobileNumber = value.replace(/\D/g, "");
  if (!Regex.PHONE_REGEX.test(mobileNumber)) return Messages.ENTER_VALID_PHONE;
  if (mobileNumber.length === 10) return OK;
  if (mobileNumber.length === 11 && mobileNumber[0] === "1") return OK;
  return Messages.ENTER_VALID_PHONE_ALT;
};
export var validateState = function validateState(value) {
  if (!value) return Messages.ENTER_STATE;
  if (!Regex.STATE_REGEX.test(value)) return Messages.ENTER_VALID_STATE;
  return OK;
};
export var validateZipCode = function validateZipCode(value) {
  if (!value) return Messages.ENTER_ZIP_CODE;
  if (!Regex.ZIP_CODE_REGEX.test(value)) return Messages.ENTER_ZIP_CODE;
  return "";
};
export var validateEIN = function validateEIN(value) {
  if (!value) return Messages.ENTER_EIN;
  var EIN = value.replace(/-/g, "");
  if (!Regex.EIN_REGEX.test(EIN)) return Messages.ENTER_EIN;
  return "";
};
export var validateDateOfBirth = function validateDateOfBirth(month, day, year) {
  if (!month || !day || !year) return Messages.ENTER_DOB;
  if (month.length !== 2 || day.length !== 2 || year.length !== 4) return Messages.INVALID_DATE_FORMAT;
  if (!moment("".concat(month, "/").concat(day, "/").concat(year), "MM/DD/YYYY").isValid()) return Messages.INVALID_DATE;
  if (moment("".concat(month, "/").concat(day, "/").concat(year), "MM/DD/YYYY").isSameOrAfter(moment().subtract(1, "days"))) return Messages.INVALID_FUTURE_DATE;
  if (moment("".concat(month, "/").concat(day, "/").concat(year), "MM/DD/YYYY").isSameOrBefore(moment().subtract(120, "years"))) return Messages.INVALID_PAST_DATE;
  return OK;
};
export var validateCardExpirationDate = function validateCardExpirationDate(month, year, twoDigitYear) {
  if (!month || !year) return Messages.ENTER_CARD_EXPIRATION_DATE;
  var errorMessage = twoDigitYear ? Messages.INVALID_CARD_SHORT_EXPIRATION_DATE_FORMAT : Messages.INVALID_CARD_EXPIRATION_DATE_FORMAT;
  var yearLength = twoDigitYear ? 2 : 4;
  var yearFormat = twoDigitYear ? "MM/YY" : "MM/YYYY";
  if (month.length !== 2 || year.length !== yearLength) return errorMessage;
  if (!moment("".concat(month, "/").concat(year), yearFormat).isValid()) return Messages.INVALID_DATE;
  return OK;
};
export var validateScheduledCompletionDate = function validateScheduledCompletionDate(date, loanAppExpirationDate) {
  if (!moment(date).isValid()) return OK;
  if (!moment(date).isSameOrAfter(moment())) return 'INVALID_PAST_SCHEDULED_COMPLETION_DATE';
  if (moment(date).isSameOrAfter(moment(loanAppExpirationDate))) return 'INVALID_PAST_TO_OFFER_COMPLETION_DATE';
  return OK;
};
export var validateCompletedOn = function validateCompletedOn(month, day, year) {
  if (!month || !day || !year) return Messages.ENTER_VALID_DATE;
  if (month.length !== 2 || day.length !== 2 || year.length !== 4) return Messages.INVALID_DATE_FORMAT;
  if (!moment("".concat(month, "/").concat(day, "/").concat(year), "MM/DD/YYYY").isValid()) return Messages.INVALID_DATE;
  return OK;
};
export var validateSSN = function validateSSN(value) {
  if (!value) return Messages.ENTER_SSN;
  if (!Regex.SSN_REGEX.test(value.replace(/\D/g, ""))) return Messages.ENTER_VALID_SSN;
  return OK;
};
export var validateSSN4 = function validateSSN4(value) {
  if (!value || value.length !== 4) return Messages.ENTER_SSN4;
  if (!Regex.SSN4_REGEX.test(value)) return Messages.ENTER_SSN4;
  if (value === '0000') return Messages.INVALID_SSN4;
  return OK;
};
export var validateHiddenStartSSN = function validateHiddenStartSSN(value) {
  value = value.replace("XXX-XX-", "");
  if (!value || value.length !== 4) return Messages.ENTER_SSN4_HIDDEN;
  if (!Regex.SSN4_REGEX.test(value)) return Messages.ENTER_VALID_SSN4;
  return OK;
};
export var validateRoutingNumber = function validateRoutingNumber(value) {
  if (!value) return Messages.ENTER_ROUTING_NUMBER;
  if (!Regex.ROUTING_NUMBER_REGEX.test(value)) return Messages.ENTER_ROUTING_NUMBER;
  return "";
};
export var validateAccountNumber = function validateAccountNumber(value) {
  if (!value) return Messages.ENTER_ACCOUNT_NUMBER;
  if (!Regex.ACCOUNT_NUMBER_REGEX.test(value)) return Messages.ENTER_ACCOUNT_NUMBER;
  return "";
};
export var validateBusinessLegalName = function validateBusinessLegalName(value) {
  if (!value) return Messages.ENTER_BUSINESS_LEGAL_NAME;
  if (value.length > 120) return Messages.MAX_LENGTH_120_EXCEEDED;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return "";
};
export var validateDoingBusinessAs = function validateDoingBusinessAs(value) {
  if (!value) return "";
  if (value.length > 120) return Messages.MAX_LENGTH_120_EXCEEDED;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  if (value.trimStart().trimEnd().length < 3) return Messages.INVALID_DOING_BUSINESS_AS;
  return "";
};
export var validateStreetAddress = function validateStreetAddress(value) {
  if (!value) return Messages.ENTER_ADDRESS;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return "";
};
export var validateCity = function validateCity(value) {
  if (!value) return Messages.ENTER_CITY;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return Messages.UNSUPPORTED_SYMBOLS + ": " + removeAllDuplicates(value.replaceAll(Regex.SAFE_TEXT_REGEX_REPLACE, ''));
  return "";
};
export var validateIndustryFreeText = function validateIndustryFreeText(value) {
  if (!value || !value.trim()) return Messages.ENTER_INDUSTRY_FREE_TEXT;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return Messages.ENTER_INDUSTRY_FREE_TEXT;
  return "";
};
export var validateIndustry = function validateIndustry(value) {
  if (!value) return Messages.ENTER_INDUSTRY;
  if (!Regex.SAFE_TEXT_REGEX.test(value)) return Messages.ENTER_INDUSTRY;
  return "";
};
import _slicedToArray from "/home/carlos.matus/wisetack/clients/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import userflow from 'userflow.js';
var initiated = false;
var userAttributes = ["partnerName", "partnerId", "merchantId", "transactionsEnabled", "userRole", "userId", "representativeType", "merchantMerchantPortalTransactionEnabled", "partnerMerchantPortalTransactionEnabled"];

var initUserflow = function initUserflow() {
  try {
    if (!initiated) {
      var _window$_wtenv_;

      if (((_window$_wtenv_ = window._wtenv_) === null || _window$_wtenv_ === void 0 ? void 0 : _window$_wtenv_.USERFLOW_TOKEN.trim().length) !== 0) {
        var _window$_wtenv_2;

        userflow.init((_window$_wtenv_2 = window._wtenv_) === null || _window$_wtenv_2 === void 0 ? void 0 : _window$_wtenv_2.USERFLOW_TOKEN);
        initiated = true;
      }
    }
  } catch (e) {
    initiated = false;
    throw e;
  }
};

export default function identifyUser(user) {
  if (user === null || user === undefined || !user.userId) {
    return;
  }

  var userflowInfo = {};

  for (var i = 0; i <= userAttributes.length; i++) {
    var attrKey = userAttributes[i];
    var attr = Object.entries(user).find(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      return key === attrKey;
    });

    if (attr !== null && attr !== undefined) {
      var value = attr[1] === undefined || attr[1] === null ? '' : attr[1].toString();

      if (typeof value === 'object') {
        userflowInfo[attrKey] = JSON.stringify(value);
      } else {
        userflowInfo[attrKey] = value;
      }
    }
  }

  initUserflow();

  if (!initiated) {
    return;
  }

  if (userflow.isIdentified()) {
    userflow.updateUser(userflowInfo);
  } else {
    userflow.identify(user.userId, userflowInfo);
  }
}